.invoiceform__container {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 3rem;
}

.invoiceform__container__form input {
    width: 310px;
    border-radius: 5px;
    height: 30px;
    outline-color: lightgrey;
    border: 1px solid;
}

.invoiceform__container__form__add {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    pad: 10px;
}

.invoiceform__container h3 {
    font-size: 30px;
    opacity: .5;
}

/* .invoiceform__container__form__add button{
    padding: 0.75rem 2rem;
    width: 200px;
    border-radius:0.5rem;
    background:linear-gradient(30deg,#8a28d9,#a168cf);
    cursor: pointer;
    color: white;
    font-weight: bold;
    border: none;
    transition: all 0.1s ease;

} */

/* padding:0.75rem 2rem;
    border-radius:0.5rem;
    background:linear-gradient(30deg,$base-sun-color,$base-sun-color-2);
    font-weight:bold;
    color:white;
    border:none;
    cursor:pointer;
    transition:all 0.1s ease;
    display:flex;
    align-items:center;
    justify-content: center;
    &:hover{
     //    box-shadow:1px 1px 20px $hovered-shadow;
        background:linear-gradient(30deg,$base-sun-color-2,$base-sun-color);
 
    } */


.invoicefrom__genAndCreateButton {
    display: flex;
    justify-content: space-around;
    margin-top: .4rem;
}

.invoicefrom__genAndCreateButton button {
    font-size: 20px;
    border-radius: 30px !important;
    width: 10rem;
    font-weight: 600;
}

.invoicefrom__genAndCreateButton button:hover {}

.invoicefrom__GenereateButton__table {
    height: 30rem;
    margin-top: 2rem;
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;

}


.previewMOdal {
    width: 100%;
    /* height: 100vh; */
    background-color: rgb(0, 0, 0, .5);
    min-height: 100vh;
    /* margin-top: -10rem; */
    position: absolute;
    animation: fadein 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.previewMOdal__body {
    background-color: #fff;
    width: 50rem;
    padding: 20px;
    margin-right: 18rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.previewMOdal__body h3 {
    color: #8a28d9;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 25px;
}

.previewMOdal__body textarea {
    width: 100%;
    outline: none;
    border-radius: 10px;
    border: 1px solid grey;
    height: 100px;
    padding-left: 10px;
    padding-top: 5px;
}

.previewMOdal__body button {
    padding: 9px;
    margin-top: 10px;
    text-align: center !important;
    background: linear-gradient(to left, #8a28d9 50%, white 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: #fff;
    font-size: large;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #8a28d9;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Montserrat;
}

.previewMOdal__body button:hover {
    background-position: left;
    color: #8a28d9;
    box-shadow: 1px 2px 5px #8a28d9;
    transition: .5s ease-out;
}

.client_Info {
    display: flex;
    width: 100%;
    padding-right: .5rem;
}

.client_info_sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #eee;
    min-height: 100vh;
    gap: unset;
    width: 20% !important;
}

.invoicefromClientINfo {
    width: 80% !important;
}

.client_info_sidebar .logo img {
    justify-self: center;
    width: 10rem;
    align-self: center;
    margin: 4rem 0 4rem 1rem;
}

.sidebar_info {
    display: flex;
    flex-direction: column;
    width: 200px;
    gap: 15px;

}

.client_name {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 10px;
}

.client_name p {
    font-family: Montserrat;

}
.client_Email{
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 10px;
    width: 100px !important;
}

.client_name img {
    width: 35px;
    background: unset !important;
    height: auto;
}

.TrackButton {
    margin-top: 10px;
    text-align: center !important;
    background: linear-gradient(to left, #8a28d9 50%, white 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: #fff;
    font-size: large;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #8a28d9;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: Montserrat;
}