.suSectionHeading{
    display:flex;
    padding:1rem 2rem;
    align-items:Center;
    justify-content: space-between;
    h2{
        text-transform: capitalize;
    }

}
.fundsTitle{
    padding:2rem;
    display:flex;
    justify-content:center;
    >div{
        display:flex;
        flex-direction:column;
        align-items: center;
        h1{
            background:$lg1;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
.allfundsBody{
    margin:1rem;
    border-radius:0.5rem;
    .routes{
        padding:1rem;
        display:grid;
        align-items:center;
        grid-template-columns: repeat(3,1fr);
        p{
            cursor:pointer;
            display:flex;
            justify-content: center;
            align-items: center;
            svg{
                font-size:0.9rem;
                margin-right:0.5rem;
                user-select: none;
                color:transparent;
            }
            font-weight:bold;
            color:grey;
            align-self:center;
            text-align:Center;
        }
    }
    .body{
        border-top:1px solid $base-sun-color;
    }
}
.fundsActiveLink{
    color:black !important;
    svg{
        color:$base-sun-color !important;
    }
}
.agentsList{
    padding:2rem;
    display:grid;
    grid-template-columns: repeat(4,1fr);
    .agentCard{
        margin:1rem;
        border-radius:0.5rem;
        min-width:12rem;
        border-bottom:2px solid transparent;
        color:black;
        &:hover{
            border-color:$base-sun-color;
        }
        box-shadow:1px 1px 10px rgba(0, 0, 0, 0.432);
        .basicIntro{
            b{
                text-transform: capitalize;
            }
            padding:0.5rem;
            border-bottom:1px solid grey;
        }
        .moreIntro{
            font-size:0.8rem;
            padding:0.5rem;
        }
    }
}
.allClients{
    margin:2rem;
    .head{
        display:flex;
        align-items: center;
        justify-content: space-between;
        .controls1{
            display:flex;
            button{
                margin:0 0.5rem;
            }
        }

    }

    .agentCard{

        p{
            b{
                display:inline-block;
                width:300px;
            }
        }
    }
    .client{
        display:grid;
        grid-template-columns: repeat(5,1fr);
        background:#eee;
        border-radius:0.5rem;
        padding:0.5rem;
        position:relative;
        overflow:hidden;
        .actions{
            position:absolute;
            right:0;
            top:0;
            bottom:0;
            display:none;
            background:linear-gradient(90deg,transparent,grey);
            backdrop-filter: blur(5px);
        }
        button{
            display:grid;
            place-items:Center;
            margin:0 0.5rem;
            border:none;
            cursor:pointer;
            background:none;
        }
        &:hover{
            .actions{
                display:flex;
            }
        }
    }
    .client:first-child{
        background:transparent;
    }
}
.editDialogDivSingle{
    display:flex;
    align-items:Center;
    justify-content: space-between;
    margin:0.5rem 0;
    p{
        margin-right:1rem;
    };
}
.fundsContainer{
    .header{
        margin:1rem;
    }
}
.funds{
    padding:2rem;
}
.fund{
    display:grid;
    align-items:Center;
    padding:0.5rem;
    grid-template-columns: repeat(5,1fr);
    background:#eee;
    border-radius:0.5rem;
}


.trackerContainer{
    padding:5rem;
    .header{
        display:flex;
        align-items:center;
        padding:1rem;
        justify-content: space-between;
    }
    .body{
        height:50vh;
        display:grid;
        place-items:center;
        position:relative;
        .markerFill{
           position:absolute;
           top:0;
           left:0;
           height:100%;
           width:0%;
           background:$lg1;
           transition:all 10s ease;
        }
        .markerContainer{
            width:80vw;
            height:5px;
            background:#eee;
            position:relative;
            .mark{
                position:absolute;
                top:0;
                left:0;
                transform:translate(-50%,-37.5%);
                border-radius:50%;
                width:20px;
                height:20px;
                background:$lg1; 
                p{
                    position:absolute;
                    top:200%;
                    width:10rem;
                    text-align:center; 
                    left:0;
                    color:#8928d979;
                    transform:translate(-42.5%,-50%);
                }   
            }
            .initiated2{
                left:20%;
            }
            .initiated3{
                left:40%;
            }
            .initiated4{
                left:60%;
            }
            .initiated5{
                left:80%;
            }
            .initiated6{
                left:100%;
            }
        }
        .markerOtherDetails{
            margin-top:5rem;
            p{
                span{
                    font-size:2rem;
                    color:$base-sun-color;
                }
                b{
                    color:$base-sun-color-2;
                }
            }
        }
    }
}
.agentSideFund{
    position:relative;
    overflow:hidden;
    button{
        position:absolute;
        display:none;
        border:none;
        cursor:pointer;
        place-items:Center;
        height:100%;
        padding:0 1rem;
        background:linear-gradient(90deg,transparent,grey);
        top:0;
        right:0;
    }
    &:hover{
        button{
            display:grid;
        }
    }
}
.paymentDrawer{
    // padding:2rem 5rem;
    width:40vw;
    height:100vh;
    display:grid;
    place-items: center;
    >div{
      padding:1rem 4rem;
    }
    .head{
        text-align: center;
        h1{
            color:$base-sun-color;
        }
    }
    .body{
        display: flex;
        flex-direction:column;
        margin:2rem 0;
        >div{
            display:flex;
            justify-content: space-between;
            align-items:Center;
            p{
                margin-right:2rem;
                font-weight:bold;
            }
        }
        textarea{
            resize:none;
            height:8rem;
        }
        input{
            margin:0.5rem 0;
            display:inline-block;
        }
    }
    position: relative;
    .footer{
        position: absolute;
        background:$lg1;
        bottom:0;
        left:0;
        right:0;
        height:10px;

    }
}
.settingsContainer{
    .heading{
        display:flex;
        justify-content: space-between;
        padding:1rem;
        align-items: center;
    }
}


.todaysBidsIndividual{
    position:relative;
    overflow: hidden;
    display:grid;
    align-items:Center;
    grid-template-columns: repeat(8,1fr);
    background:#eee;
    padding:0.5rem;
    border-radius:0.5rem;
    margin:2px 0;
    .controlsTBI{
        position: absolute;
        border-radius:5rem 0 0 5rem;
        right:-100%;
        height:100%;
        top:0;
        opacity:1;
        pointer-events: none;
        background:linear-gradient(90deg,transparent,grey);
        backdrop-filter:blur(5px);
        display:flex;
        transition:all 0.3s ease;
        button{
            background:transparent;
            border:none;
            background:transparent;
            margin:0 1rem;
            cursor:pointer;
        }
        .winner{
            color:#006b17;
            font-weight:bold;  
            font-size:1.1rem;
            font-family: montserrat; 
        }
    }
    &:hover{
        .controlsTBI{
            right:0;
            opacity:1;
            pointer-events: all;
        }

    }
}
.managementActions2{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:1rem 0 2rem 0;
}

.fundsSubContainer{
  padding-top:1rem;
  .vbaIndividual{
      display:grid;
      grid-template-columns: repeat(3,1fr);
      justify-content: space-between;
      align-items:Center;
      margin:5px 0;
      background:#eee;
      border-radius:0.5rem;
      padding:0.5rem;
      justify-items: center;
      position:relative;
      .vbcarcontrol{
          transition:all 0.1s ease;
          position:absolute;
          top:0;
          right:0;
          pointer-events:none;
          opacity:0;
      }
      >div{
        display:flex;
        // justify-content: ;
        button{
            border:none;
            background:$base-sun-color;
            padding:0.2rem 0.4rem;
            border-radius:0.3rem;
            color:white;
            cursor:pointer;
            margin:0 0.2rem;
            display:grid;
            place-items:Center;
        }  
      }
    &:hover{
        .vbcarcontrol{
            opacity:1;
            pointer-events: visibleFill;    
        }
   } 
  }
}