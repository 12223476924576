$base-sun-color:#8a28d9;
$base-sun-color-2:#a168cf;
$hovered-shadow:grey;
$gray-shade1:#eee;
$gray-shade2:#c4c4c4;
$gray-shade3:#8d8d8d;
$base-bg-shade1:#fff;
$base-bg-invoice:#c59a6b;
$base-shadow-shade1:rgba(214, 214, 214, 0.25);
$base-sun-fontColor:black;
$lg1:linear-gradient(30deg,$base-sun-color,$base-sun-color-2);

// c4996c