.sapurchaseModal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    margin-top: -3rem !important ;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadein 1s;
    font-family: '"Montserrat", sans-serif';
    z-index: 100;
}
.DocContainer{
    max-height: 70px !important;
    height: 70px !important;
    overflow-y: auto;
    margin-bottom: 5px !important;
}
.DeleteDOC svg{
    background-color: unset !important;
    /* all:revert !important; */
    color: #8a28d9;
    width: unset !important;
    font-size: unset;
    padding: unset !important;
    margin-top: 23px;
}
.DeleteDOC{
    gap: 20px;
}
.DeleteDOC button{
    background-color: unset !important;
    /* all:revert !important; */
    width: unset !important;
    padding: unset;
    border: unset !important;
    box-shadow: unset !important;
}
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.sapurchaseModal__body {
    width: fit-content;
    height: auto;
    background-color: #fff;
    color: black;
    margin-right: 19rem;
    border-radius: 10px;
    position: relative;



}

.sapurchaseModal__body__tracker {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #8a28d9;

}

.sapurchaseModal__body__container {
    height: 40rem;
    max-height: 38.5rem;
    overflow-y: auto;
}

.sapurchaseModal__body__card__right {
    margin-top: 1rem;
}

.sapurchaseModal__body__card__right p {
    font-family: Montserrat;
    font-size: medium;
    font-weight: 600;
}

.sapurchaseModal__body__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;

}

.sapurchaseModal__body__card {
    gap: 30px;
    font-family: Montserrat;
}

.sapurchaseModal__body__card__left h3 span {
    font-size: 15px;
    font-family: Montserrat;
}

.sapurchaseModal__body__card__left img {
    display: flex;
    object-fit: contain;
    width: 100px;
}

.sapurchaseModal__sort {
    width: 30rem;
    height: auto;
    background-color: #fff;
    color: black;
    margin-right: 19rem;

    border-radius: 10px;

}

.visibilityMOdal {
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.sapurchaseModal__sort__title {
    font-family: Montserrat;
    text-align: center;
    margin-bottom: 1rem;
    width: 100%;
    top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #8a28d9;
}

.sapurchaseModal__sort__title h2 {
    font-family: Montserrat;
    text-align: center;
    margin-bottom: 1rem;
    color: #fff;
    padding: 5px;
}

.sapurchaseModal__sort__table {
    padding: 20px;
}

.sapurchaseModal__sort__table input {
    height: 30px;
    outline: none;
    border: none;
    border-radius: 10rem;
    box-shadow: 7px 4px 16px 0px #ccc;
    padding: 10px;
    font-family: Montserrat;
}

.sapurchaseModal__sort__submit button {
    margin-top: 1rem;
    height: 40px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 5px;
    box-shadow: 2px 5px 12px 5px #ccc;
    border-radius: 5rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #8a28d9;
    color: #fff;
    transition: all .5s ease-in-out;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1rem;
    border: 1px solid #8a28d9;

}

.sapurchaseModal__sort__submit__button button {
    margin-top: 1rem;
    height: 40px;
    width: 100%;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 5px;
    box-shadow: 2px 5px 12px 5px #ccc;
    border-radius: 5rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #8a28d9;
    color: #fff;
    transition: all .5s ease-in-out;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1rem;
    border: 1px solid #8a28d9;
}

.sapurchaseModal__sort__submit button:hover,
.sapurchaseModal__sort__submit__button button:hover {
    color: #8a28d9;
    background-color: #fff;
    transition: all .5s ease-in-out;

}

.sapurchaseModal__sort__submit {
    width: 100%;
    
}

.sapurchaseModal__sort__submit .upload {
    position: relative;
    width: 31rem;
    cursor: pointer !important;
    
}

.sapurchaseModal__sort__submit .upload span {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer !important;
    background: #8a28d9;
    width: 20rem;
    text-align: center;
    padding: 7px 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;


}

.sapurchaseModal__sort__submit .upload input {
    position: absolute;
    /* visibility: hidden; */
    opacity: 0;
    cursor: pointer !important;
    /* right: 9rem; */
    width: 20rem;
    z-index: 10;
    top: 0;
    right: 0;
    padding: 7px 5px;

}

.sapurchaseModal__body__card__right_details {
    display: flex;
    gap: 10px;
}

.sapurchaseModal__body__card__tracker {
    display: flex;
    width: fit-content;
    justify-content: center;
    padding-bottom: 1rem;
    /* padding-right: 1rem; */
    /* padding-left: 1rem; */
    gap: 3rem;
}

.sapurchaseModal__body__card__tracker__text {
    margin-top: .5rem;
    padding: 10px 4px 10px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.sapurchaseModal__body__card__TrackStatus {
    margin-bottom: 1rem;
    width: 100%;
    /* text-align: center; */
}

.sendLinkBUtton {
    /* margin-left: 15rem; */
    flex: .5;
    display: flex;
    margin-right: .1rem;
    justify-content: flex-end;
}

.trackEdit {
    display: flex;
    flex: .7;
    gap: 10px;
    margin-left: 10rem !important;
    justify-content: flex-end;
}

.sapurchaseModal__body__card__TrackStatus button {
    padding: 2px;
    width: 4.5rem;
    cursor: pointer;
    height: 30px;
    margin-top: 1rem;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 2px 5px 12px 5px #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #8a28d9;
    color: #fff;
    transition: all .5s ease-in-out;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 1rem;
    border: 1px solid #8a28d9;
}

.sapurchaseModal__body__card__TrackStatus button:hover {
    color: #8a28d9;
    background-color: #fff;
    transition: all .5s ease-in-out;
}

.sapurchaseModal__body__card__TrackStatus h2 {
    font-family: "Montserrat", sans-serif;
}

.TrackingStatusButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.TrackingStatusButton svg {
    color: #8a28d9;
    font-weight: 600;
    cursor: pointer;
    margin-top: 2px;
}

.sapurchaseModal__sort__submit input {
    background-color: #8a28d9;
}