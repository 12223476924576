@import "variable"; 

.reminderContainer{
    .dbcNavbar{
        display:flex;
        justify-content: space-between;
        padding-bottom:1rem;            
        border-bottom:1px solid grey;
    }

}
