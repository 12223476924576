.card {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 1rem;
    z-index: 0;
  }
  
  .card_top {
    display: flex;
    align-items: flex-start;
  }
  
  .card_top_labels {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
    line-height: 21px;
  }
  
  .card_top_labels label {
    border-radius: 40px;
    padding: 4px 12px;
    background-color: gray;
    color: #fff;
    width: fit-content;
  }
  
  .card_top_more {
    width: 30px;
    height: 20px;
    transform: translateX(15px);
    flex: 1;
    cursor: pointer;
    opacity: 0;
    transition: 200ms;
    position: relative;
  }
  
  .card:hover .card_top_more {
    opacity: 1;
  }
  
  .card_title {
    flex: 1;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.875rem;
  }
  
  .card_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card_footer_item {
    border-radius: 40px;
    padding: 4px 12px;
    background-color: #f8f8f8;
    color: #000;
    width: fit-content;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .card_footer_icon {
    height: 13px;
    width: 13px;
  }
  
  .card_footer_user {
    border-radius: 50%;
    height: 28px;
    width: 28px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .board_dropdown{
    width: 140px;
    background-color: #ccc;
    margin-right: 1rem;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
  .board_dropdown span svg{
    font-size: 10px;
  }
  