.invoicePdf {
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    height: auto;
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
    padding-top: 2rem !important;
    

}
.carName{
font-family: "ヒラギノ角ゴ Pro W3" !important;

}
.invoicePdf__Main{
    height: auto;
}
.invoicePdf__Main p {
    font-family: Calibri, sans-serif !important;
    font-size: 18px;
    letter-spacing: 1px;
}
.invoicePdf__Main h3 {
    font-family: Calibri, sans-serif !important;
    font-size: 22px;
   
}
.invoicePdf__Main h4 {
    font-family: Calibri, sans-serif !important;
    font-size: 22px;
   
}

.invPrint {

    position: relative;

}

.PdfButton {
    padding: 5px;
    text-align: center;
    background: linear-gradient(to left, #8a28d9 50%, white 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: #fff;
    font-size: large;
    width: 120px !important;
    margin-right: 19px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid #8a28d9;
    outline: none;
    cursor: pointer;
    font-weight: bold;

}

.PdfButton:hover {
    background-position: left;
    color: #8a28d9;
    box-shadow: 1px 2px 5px #8a28d9;
}

.invoicePdf__topContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem !important;   
    margin-left: 5rem;
    /* max-height: 30rem; */
    overflow-y: auto;
    /* height: 30rem !important; */
    margin-right:5rem !important;
    margin-top: 1.5rem;

}

.invoicePdf__top {
    display: flex;
    justify-content: flex-start;
    width: 200px;
}

.invoicePdf__top img {
    width: 187% !important;
    object-fit: contain;
    background-blend-mode: color-dodge;
}

.invoicePdf__top h3 {
    font-size: 40px;
    text-align: left;
    font-weight: 700;
   
}

.Horizontal_Line {
    margin-bottom: 20px;
    margin-top: 20px;
    height: 2.5px !important;
    width: 100%;
    background-color: #c59a6b;
}

.invoicePdf__dateSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.invoicePdf__dateSection h3 {
    height: 20px;
    font-size: 30px;
    margin-bottom: 2rem;
  
    color: #000 !important;
    
}

.invoicePdf__dateSection__client {
  
    text-align: left;
}

.invoicePdf__dateSection__client p {
    margin-top: 5px;
  
}

.invoicePdf__dateSection__client__bank {
    display: flex;

}

.invoicePdf__dateSection__table {
    width: 100%;
}

.invoicePdf__dateSection__client__address {
    display: flex;
    justify-content: flex-start;

}

.invoicePdf__dateSection__client__address p {
    margin-left: .5rem;
   
}

.invoicePdf__dateSection__client__address h3 {
    margin-left: .5rem;
    
}

.ClosePreview {
    display: flex;
    justify-content: space-evenly;
    gap: 15rem;
}

.invoice__Heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.invoiceHeading_info p{
    line-height: 1.5;
}
.grandTotal{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}
.grandTotal p {
    font-size: 30px;
    margin-left: 20px;
}
.doimondUl ul {
    margin-left:3rem !important;
    list-style: square; /* Remove default bullets */
    margin-bottom: 5px;
  }
.doimondUl ul li {
    list-style: none !important;
    /* font-family: Calibri, sans-serif !important; */
    font-weight: 500;
    font-size: 20px;
  }
  
.doimondUl ul li::before {
    content: "\2022";  
    color: #000; 
    font-weight: bold; 
    display: inline-block;
    width: 1em; 
    margin-left: -1em; 
    list-style: none !important;
    font-family: Calibri, sans-serif !important;
  }
  .bank{
    display: flex;
    justify-content: center;
    width: 100% !important;
    flex-direction: column;
  }
  .bank h3{
    text-align: center;
  }
  .bankDetails{
    display: flex !important;
    justify-content: center !important;
    gap:150px;
    width: 100% !important;
  }
  .bankDetails__one
  {
    flex: .5;
    line-height: 1.5;
  }
  .bankDetails__two
  {
    flex: .5;
    line-height: 1.5;
  }
  .bankDetails__Info{
    margin-top: 5px !important;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
  }
  .bankDetails__Info p {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }