.dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  border-radius: 3px;
  min-height: 40px;
  min-width: 80px;
  width: fit-content;
  height: fit-content;
  max-width: 250px;
  max-height: 390px;
  overflow-y: auto;
  z-index: 5;

}
