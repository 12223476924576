@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.sapurchase__header {
    text-align: center;
    margin-top: 5px;
    color: #8a28d9;
}

.sapurchase {
    width: 100% !important;
}
.bankSelect{
    background-color: #fff;
    width: 39rem;
    padding: 20px;
    margin-right: 18rem;
    border-radius: 10px;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.sapurchase__dateSearch__conatiner {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    border: 1px solid grey;
    margin-left: 1rem;
    margin-bottom: .5rem;
    border-radius: 5px;
}
.previewMOdal{
    z-index: 100;
}
.sapurchase__dateSearch__left {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

.sapurchase__dateSearch__left h3 {
    font-family: Montserrat;
}

.sapurchase__dateSearch__left input {
    height: 30px;
    font-family: Montserrat;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0px 6px 8px -3px #8d8d8d;
    cursor: pointer;
    outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
.sapurchase__dateSearch__Right__botton{
    display: flex;
    align-items: baseline;
}
.sapurchase__dateSearch__Right__botton button {
    padding: 5px;
    text-align: center;
    background: linear-gradient(to left, #8a28d9 50%, white 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: #fff;
    font-size: large;
    width: 100px;
    margin-right: 5rem;
    font-family: Montserrat;
    border-radius: 5px;
    border: 1px solid #8a28d9;
    outline: none;
    cursor: pointer;
    font-weight: bold;
}
.sapurchase__dateSearch__Right__generate{
    display: flex;
    justify-content: center;
}
.sapurchase__dateSearch__Right__generate button{
    padding: 5px;
    text-align: center;
    background: linear-gradient(to left, #8a28d9 50%, white 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    color: #fff;
    font-size: large;
    font-family: Montserrat;
    border-radius: 5px;
    border: 1px solid #8a28d9;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10rem;
}
.sapurchase__dateSearch__Right__generate button:hover{
    background-position: left;
    color: #8a28d9;
    box-shadow: 1px 2px 5px #8a28d9;
}
.sapurchase__dateSearch__Right__botton button:hover {
    background-position: left;
    color: #8a28d9;
    box-shadow: 1px 2px 5px #8a28d9;

}

.sapurchase__dateSearch {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: Montserrat;
    margin-bottom: 20px;
}

.sapurchase__table__container__main {
    width: 100%;
}

.sapurchase__table__container {
    width: 100%;
    margin-bottom: 1rem;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
}

.cAndFPrice {
    display: flex;
    height: 30px;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px;
    gap: 10px;
    border: 1px solid grey;
    width: 150px;
    justify-content: flex-end;
}

.cAndFPrice input {
    outline: none;
    border: none;
    padding: 5px;
    width: 100%;

}

.cAndFPrice__div {
    display: block;
    margin-right: 1rem;

}

.cAndFPrice .edit {
    background-color: #8a28d9;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: 50px;
    transition: all .5s ease-in-out;

}

.cAndFPrice .save {
    background-color: #8a28d9;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: 50px !important;
    transition: all .5s ease-in-out;
}

.purchaseDoc span {
    background-color: #8a28d9;
    color: #fff;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}

.InvPrint {
    position: absolute;
    display: flex;
    text-align: left;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    right: 0;
    opacity: 0;
    width: 10rem;
    background: rgba(0, 0, 0, .4) !important;
    backdrop-filter: blur(5px);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.InvPrint button {
    border: none;
    background: transparent;
    font-size: 20px;
    max-width: 0;
    color: #8a28d9;
    margin-left: 2rem;
    cursor: pointer;
    font-weight: 700;
}


.sapurchase__header__main{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.sapurchase__header__main p {
    height: 20px;
    margin-top: 5px;
    width: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 12px 4px #cccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #8a28d9;
    cursor: pointer;
}

@media screen and (max-width:1300px) {
    .sapurchase__table__container {
        width: 64rem;
        max-width: 64rem;
        overflow-x: auto;
    }
}
@media only screen and (max-width:1300px) {
    .showHidDelverdNotComplete{
        right: 5px !important;
    }
    .showHid{
        right: 5px !important;
    }
    .showHidDelverd{
        right: 5px !important;
    }
}