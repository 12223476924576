/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.notificationIcon {
  /* position: fixed; */
  margin-top: 15px;
  margin-right: 23px;
  z-index: 500;
  color: grey;
  display: flex;
  justify-content: flex-end;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.notificationIcon svg {

  font-size: 30px;
}

.notificationIcon span {
  position: absolute;
  right: 5px;
  top: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: large;

  font-weight: bold;
  background-color: red;

}

.NotificationiconColo {
  color: #fff;
}

.MuiTableCell-root {
  font-family: Montserrat !important;

}

/* .k-dropdown-wrap>.k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list, .k-reset {
  background-color: lightblue;
} */

input[type="date"]::before {
  color: #999999;
  content: attr(placeholder);
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "" !important;
}